/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */
'use strict';
__webpack_public_path__  = window.$__webpack_public_path__;

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    aep = require('@foundation/js/aepdatacollection-util'),
    page = require('./page'),
    searchplaceholder = require('./searchplaceholder'),
    menuSearch = require('./menu-search'),
    errorsearchsuggest = require('./errorsearchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    couponCode = require('./coupon-code'),
    ccValidator = require('./credit-card-validation'),
    login = require('./login'),
    errorIcon = require('./error-icon'),
    forms = require('./forms'),
    globalScroll = require('@foundation/js/global-scroll'),
    pdpscroll = require('./pages/productRefresh/pdp-scroll'),
	giftCard = window.pageContext.ns ==='giftCardRefresh' ? require('./pages/giftCardRefresh/gift-cards') : require('./giftcert'),
	accordion = require('./accordion'),
    tabs = require('./tabs'),
    navigation = require('./navigation'),
    AsyncRunner = require('@foundation/js/pages/async-runner'),
    guidedshopping = require('./pages/guidedshopping'),
    onetrust = require('./onetrust'),
    welcomemodal = require('./welcomemodal'),
    tradeprogram = require('./pages/tradeprogram'),
    favoriteStore= require('./favoriteStore'),
    toaster = require('@foundation/js/toaster'),
    fixBrokenImages = require('@foundation/js/pages/productRefresh24/fixBrokenImages'),
    _ = require('lodash');
    require('@foundation/js/SwiperProxy');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./menu')();
require('./social')();
require('./horizontal-carousel')();
require('./header-login')();
require('./pages/emailsignup')();
require('./pages/countryselector')();

var insertOptanonScript = function() {
    if ($('.js-reviews-flyout-wrapper').data('isbvcookieenabled') && $('.js-bv-header')) {
        var OptanonConsent = util.getCookie('OptanonConsent');
        if (OptanonConsent && OptanonConsent.indexOf('3:1') > -1) {
            var bvurl = $('.js-bv-header').data('bvurl');
            Optanon.InsertScript(bvurl, 'head', null, null, 1);
        }
    }
}

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    //PREVAIL-Added to handle form dialog boxes server side issues.
    $('body').on('click', '.dialogify, [data-dlg-options], [data-dlg-action]', require('./dialogify').setDialogify)
        .on('keydown', 'textarea[data-character-limit]', function(e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function() {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    $('body').on('focusout keyup', '.restrict-emoji input', function() {
        this.value = this.value.replace(/[^\u0020-\u007F\u00A0-\u00FF\u20AC]/g, '');
    });
    $('[id*="profile_customer_email"], [id*="profile_login_passwordconfirm"], [id*="billing_paymentMethods_creditCard_number"]').on('focusout keyup', function() {
        $(this).parent().next('.form-caption.error-message').hide();
    });
    $('.gift-card-links a').on('click',function(e) {
        if ($(this).hasClass('active')) {
            e.preventDefault();
        }
    });

    //accessibility
    var screenReaderDigits = $('.screen-reader-digits');

    if (screenReaderDigits.length) {
        var splitDigits = screenReaderDigits.attr('aria-label').split('').join(' ');
        
        if (screenReaderDigits.length) {
            screenReaderDigits.attr('aria-label', splitDigits);
        }
    }

    //handle errors for screen readers

    $(window).on('load', function() {
        setTimeout(function() {
            if ($('.error-message:not(:empty)').length || $('.error-form:not(:empty)').length) {
                //handle errors for screen readers
                var headerHeight = $('.top-banner-sticky').outerHeight();
                $('.error-form:not(:empty)').filter(':visible').clone().appendTo( "#live-error-messages" );
                $('.error-message').filter(':visible').clone().appendTo( "#live-error-messages" );
                var firsterror = $('.error-message').filter(':visible').first();
                if (firsterror.length) {
                    $('html, body').animate({
                        scrollTop: firsterror.offset().top - headerHeight - 100
                    }, 1000);
                }                
                $('.error-message').filter(':visible').first().siblings('.field-wrapper').find('input').trigger('focus');
            }
           
        }, 1000);

        //scroll to hash
        if (window.location.hash) {
            var hash = location.hash.replace('#','');
            var headerHeight = $('.top-banner-sticky').height();

            if(hash != '' && hash === 'checkorder'){

                $('html, body').animate({ scrollTop: $('#'+hash+'').offset().top - headerHeight}, 250);

            }
        }
        //make chat clickable with keyboard
        $('.cx-webchat-chat-button').on('keydown',function (e) {
            var key = e.which;
            if( key == 13 || key == 32 )  {// the enter key code
                $(this).trigger('click');//Trigger click for keyboard accessibility
                return false;  
            }
        }); 
        
    });
    
    //handle outline for tabbing users
    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.body.classList.add('user-is-tabbing');
        
            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    }

    function handleMouseDownOnce() {
        document.body.classList.remove('user-is-tabbing');
      
        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    }

    window.addEventListener('keydown', handleFirstTab);

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.header-search');
    var $nav = $('header');
    menuSearch($searchContainer, $nav, Resources.SIMPLE_SEARCH);
    if ($('.error-page-search').length > 0) {
        var $errorSearchContainer = $('.error-page-search');
        errorsearchsuggest.init($errorSearchContainer, Resources.SIMPLE_SEARCH);
    }

    // print handler
    $('.print-page').on('click', function() {
        window.print();
        return false;
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').on('click',function(e) {
        e.preventDefault(); //JIRA PREV-90 : When click on advance search from gift registry login page, focus is happening towards top of the page.
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.on('focus',function() {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({
                color: '#999999'
            }, 500, 'linear', function() {
                $(this).val('').css('color', '#333333');
            });
        }).on('blur',function() {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({
                    color: '#333333'
                }, 500, 'linear');
        });
    }

    $('input[type="checkbox"]').on('change',function() {
        var checkboxlabel = $(this).next('label');
        if($(this).is(":checked")) {
            checkboxlabel.attr('aria-checked', true);
        } else {
            checkboxlabel.attr('aria-checked', false);
        }

    });
    
	$('.rememberme').each(function (i) {
        $(this).find('input').prop('id', 'rememberme-' + (i + 1));
        $(this).find('label').prop('for', 'rememberme-' + (i + 1));
    });

    $('.privacy-policy').on('click', function(e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    $('.security-msg').on('click', function(e) {
        e.preventDefault();
        var markup = '<p>' + Resources.SECURITY_CONTENTONE + '</p><p>' + Resources.SECURITY_CONTENTTWO + '</p><p>' + Resources.SECURITY_CONTENTTHREE + '</p>';
        dialog.open({
        	html: markup,
        	options: {
	            modal: true,
	            height: 600,
	            resizable: false,
	            width: '70%',
	            title: Resources.SECURITY_TITLE
        	}
        });
    });


    $('body').on('keydown', '[name$="_couponCode"]', function() {
        var $this = $(this);
        if ($this.val().length >= 0) {
            $this.closest('.promo-code').closest('.promo-code-accordion').find('button#add-coupon').prop('disabled', false);
        }
    });
    $('.cvn-msg').on('click', function(e) {
        e.preventDefault();
        var markup = $('#cvn-dialog').html();
        dialog.open({
        	html: markup,
        	options: {
	            modal: true,
	            height: 600,
	            resizable: false,
	            width: '50%',
	            title: 'CVV'
        	}
        });
    });

    
    //product set view options button
    $('.button.view-options').on('click',function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('#product-set-list').offset().top
        }, 1000);
        $('#product-set-list').attr("tabindex",-1).trigger('focus');
    });

    //footer toggle
    if($(window).width() < 960) {
        var lastfooteritem = '';
        (function($) {

            var allPanels = $('.footer-item ul').hide().attr('aria-hidden', true);
            $('.footer-item h3').attr('role', 'button').attr('aria-expanded', false);

            $('.footer-item h3').on('click',function() {
                allPanels.slideUp().attr('aria-hidden', true);
                $('.footer-item h3').removeClass('open-footer').attr('aria-expanded', false);
                if ($(this).text() != lastfooteritem) {
                    $(this).next().slideDown().attr('aria-hidden', false);
                    $(this).addClass('open-footer').attr('aria-expanded', true);
                    lastfooteritem = $(this).text();
                } else {
                    lastfooteritem = '';
                    $(this).removeClass('open-footer');
                }
                return false;
            });
        })(jQuery);
    }

    //ET footer Email Sign up.
    $('#email-alert-signup').on('submit', function(e) {
        e.preventDefault();
        var $form = $(this),
            emailaddress = $form.find('input').val(),
            emailSource = $form.find('#email-source').val(),
            $emailError = $form.closest('.footer-email-signup').find('.EmailErrorMsg'),
            $emailSuccess = $form.closest('.footer-email-signup').find('.email-success'),
            url = $form.attr('action'),
            filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

        if (emailaddress == '') {
            return false;
        }

        if (emailSource == null || emailSource == '') {
            emailSource = '';
        }

        if (!filter.test(emailaddress)) {
            $emailError.addClass('error');
            $emailError.text(Resources.VALIDATE_EMAIL);
            if ($emailSuccess.find('.thank-msg').text() !='') {
                $emailSuccess.addClass('hide');   
            }
            return false;
        }

        $emailError.removeClass('error');
        $emailError.addClass('hide');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            url: util.appendParamsToUrl(url, {
                emailAddress: emailaddress,
                emailSource: emailSource
            }),
            success: function(response) {
                var resData = response.result;
                if (resData === 'true') {
                    if ($emailSuccess.hasClass('hide')) {
                        $emailSuccess.removeClass('hide');
                    }
                    $emailSuccess.find('.thank-msg').text(response.message);
                } else {
                    if ($emailError.hasClass('hide')) {
                        $emailError.removeClass('hide');
                    }
                    $emailError.text(response.message);
                }
            }
        });
    });

    if ($('.complete-the-look').length == 0) {
        $('.pdp-the-look').remove();
        $('#pdp-the-look').remove();
    }

    $('#email-alert-signup').on('focus keyup', function (e) {
        if ($('.email-success').find('.thank-msg').text() !='') {
            $('.email-success').addClass('hide');   
        }
    });
    $('input.donationAmountRange').attr('type', 'tel');

    //ED global Email Sign up.
    $('.global-email-signup').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this),
            $emailAddress = $form.find('input[name$="emailname"]'),
            $emailCollectionSource = $form.find('input[name$="email_collection_source"]'),
            $emailValidate = $form.find('.email-validate'),
            $emailSuccessShow = $form.find('.email-success-show'),
            $emailErrorMsg = $form.find('.Email-Error-Msg'),
            url = $form.attr('action') + '?' + $form.serialize(),
            filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

        function isError() {
            var errorArr = [];
            $form.find('.required').each(function (index, element) {
                var $element = $(element);
                if (!$element.hasClass('sourcecode') && ($element.val() == '' || $element.val() == null)) {
                    errorArr.push($element);
                }
            });
            return (errorArr.length > 0) ? true : false;
        }

        if ($emailCollectionSource.val() == null || $emailCollectionSource.val() == '') {
            $emailCollectionSource.val('');
        }

        if(!filter.test($emailAddress.val())) {
            if($emailValidate.hasClass('hide')) {
                $emailValidate.removeClass('hide');
                $emailSuccessShow.addClass('hide');
            }
            $emailValidate.find('.Email-Error-Msg').text(Resources.VALIDATE_EMAIL);
            return false;
        } else {
            $emailValidate.find('.Email-Error-Msg').text('');
        }

        if (!isError()) {
            $.ajax({
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                url: url,
                context: this,
                success: function(response) {
                    var resData = response.result;
                    if(resData === 'true') {
                        if($emailSuccessShow.hasClass('hide')) {
                            $emailSuccessShow.removeClass('hide');
                            $emailValidate.addClass('hide');
                        }
                        $emailSuccessShow.find('.thank-msg').text(response.message);
                        $form[0].reset(); // clear form after success
                    } else {
                        if($emailValidate.hasClass('hide')) {
                            $emailValidate.removeClass('hide');
                        }
                        $emailErrorMsg.text(response.message);
                    }
                }
            });
        }
    });

    // ED global Email Consent Checkbox
    $('.global-email-signup .consent').on('change', function (e) {
        var $form = $(this).closest('form'),
            $button = $form.find('#sourceCode-submit'),
            $consentBoxWrapper = $form.find('#email-alert-consent_wrapper');

        if (e.target.checked) {
            $button.prop('disabled',false);
            $consentBoxWrapper.removeClass('error');
            $form.addClass('consented');
        } else {
            $button.attr('disabled', 'disabled');
            $consentBoxWrapper.addClass('error');
            $form.removeClass('consented posted');
        }
    });

    // Employee, Trade user,... Shopping terms Toaster
    var $agreementModal = $('div.user-shopping-terms')
    if ($agreementModal.length > 0) { 

        var content = $agreementModal;
        if ($(window).width() <= 767) {
            toaster.teleport($(content), 'bottom', null, 'full-height user-shopping-terms-toaster', true)
                .then() 
        } else {
            toaster.teleport($(content), 'right', null, 'user-shopping-terms-toaster', true)
                .then() 
        }
    }
    //Email Signup Modal Window Load
    $(window).on('load', function() {
        $('.select.dwfrm_storelocator_country select#dwfrm_storelocator_country').on("change",function(){
            if ($(this).find('option:selected').val() == null || $(this).find('option:selected').val() == '') {
                $('.dwfrm_storelocator_country').find('label[for="dwfrm_storelocator_country"]').html('<span class="required-indicator" aria-hidden="true"> * </span>'+ Resources.Select_Country);
            } else {
                $('.dwfrm_storelocator_country').find('label[for="dwfrm_storelocator_country"]').html('<span class="required-indicator" aria-hidden="true"> * </span>'+ Resources.Country);
            }
            aep.coreCall('storeLocatorInteraction', 'countryChange', $('#dwfrm_storelocator_country option:selected').text());
        });
        
        if($('.pdpLeftNav').find('li').length > 1) {
            $('.pdpLeftNav').css('display', 'block');
        }
        if (util.isMobile()) {
            util.setCookie('isDeviceMobileLoad', 1, (60 * 24 * 365 * 10));
        }
        
        insertOptanonScript();


        onetrust.onModalClose(insertOptanonScript);

        if ($('#emailsignupform').length > 0 && document.cookie.indexOf('modalLoad') < 0 && window.location.href.indexOf("Login-GatedSale")==-1) {
            var openEmailSignupFormModal = function() {
                util.setCookie('modalLoad', 1, (60 * 24 * 365 * 10))
                $('#emailsignupform').dialog({
                    autoOpen: true,
                    width: 800,
                    modal: true,
                    dialogClass: 'emailsignupform'
                });
            };

            if (welcomemodal.isAvailable()) {
                $(document).on('welcomemodal.closed', openEmailSignupFormModal);
            } else if (onetrust.isModalAvailable()) {
                onetrust.onModalClose(openEmailSignupFormModal);
            } else {
                openEmailSignupFormModal();
            }
        }

        if($('#emailsignuppromotion').length > 0) {
	        $('#emailsignuppromotion').dialog({
	            autoOpen: true,
	            modal: true,
	            dialogClass: 'emailsignuppromotion',
	            show: 'fade',
	            open: function( event, ui ) {
	            	$('body').addClass('stop-scrolling');
                    $(this).parent().focus();
	            },
	            close: function( event, ui ) {
	            	$('body').removeClass('stop-scrolling');
	            }
	        });
        }

        //click/touch event to close the dialog
        $('.ui-dialog button.ui-dialog-titlebar-close').off('click touchstart').on('click touchstart',function(e){
        	e.preventDefault();
        	$(this).closest('.ui-dialog').find('.ui-dialog-content').dialog('close');
            return false;
        });
    });

    //CYO spinner
    function showLoader() {
        setTimeout (function check_spinner() {
            if (!$('.fc-wrapper').length == 0) {
                var cyoHeight = $('#fluidConfigure').outerHeight();
            }

            $('.cyoSpinnerContainer').css({'height': cyoHeight});

            if (!$('.fc-wrapper').length == 0 && $('.fc-preloader-container').length == 0) {
                $('.cyoSpinnerContainer').fadeOut(200);
            } else {
                setTimeout (check_spinner, 300);
            }
        }, 300);
    }

    if ($('.cyoSpinnerContainer') != undefined || $('.cyoSpinnerContainer') != null) {
        showLoader();
    }

    //ticker countdown logic
    if ($('.countdown-wrapper:not(initialized)').length > 0) {
        require.ensure([], function (require) {
            var chunk = require('@foundation/js/countdownTicker');
            if (chunk.initCountdowns) {
                chunk.initCountdowns();
            }
        }, function (error) {
            console.log('error!', error);
        }, 'widgets'); // we could group more tiny libs in there
    }
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
    util.phoneValidation();
}

// GlobalE sites uses app_rleu_core files
var pages = {
    account: './pages/account',
    cart: './pages/cart',
    //checkout: './pages/checkout', // checkout folder can be deleted
    compare: './pages/compare',
    productRefresh: './pages/productRefresh',
    productRefresh24: '@foundation/js/pages/productRefresh24',
    rlhome: './pages/enhancedPDP',
    returns: '@foundation/js/pages/returns',
    registry: './pages/registry',
    search: './pages/search',
    storefront: './pages/storefront',
    wishlist: './pages/wishlist',
    qrcode: './pages/qrcode',
    qrcodeRD: './pages/qrcodeRD',
    storelocator: './pages/storelocator',
    customerservice: './pages/customer-service',
    stylequiz: './pages/stylequiz',
    myWardrobe: './pages/wardrobe',
    homeInquiry: './pages/homeInquiry',
    magazine: '@rlmagazine/js/pages/magazine',
    rlmag: '@rlmagazine/js/pages/rlmag',
};

var app = {
    init: function() {
        window.AsyncRunner = AsyncRunner.getInstance(); // INTENTIONALLY setting global

        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html('<span class="alert-icon"></span>' + Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        $.extend(page, window.pageContext);
        var ns = page.ns;
        initializeDom();
        initializeEvents();
        // init specific global components
        navigation.init();
        fixBrokenImages.init();
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        searchplaceholder.init();
        couponCode.init();
        ccValidator.init();
        errorIcon.init();
        login.init();
        forms.init();
        globalScroll.init();
        pdpscroll.init();
        accordion.init(ns == 'productRefresh');
        tabs.init();
        guidedshopping.init();
        welcomemodal.init();
        favoriteStore.init();
        this.cookieConsent.init();
        tradeprogram.init(); //This is the only place other than spc where checkout folder is being included.

        // giftcards
        if (ns === 'giftCardRefresh'){
            require.ensure([], function (require) {
                var chunk = require('./pages/giftCardRefresh/gift-cards');
                if (chunk.init) {
                    chunk.init();
                }
            }, function (error) {
                console.log('error!', error)
            }, 'giftCardRefresh');
        } else if (ns === 'giftCardRefresh24'){
            require.ensure([], function (require) {
                var chunk = require('./pages/productRefresh24/gift-cards');
                if (chunk.init) {
                    chunk.init();
                }
            }, function (error) {
                console.log('error!', error)
            }, 'giftCardRefresh');
        } else {
            require.ensure([], function (require) {
                var chunk = require('./giftcert');
                if (chunk.init) {
                    chunk.init();
                }
            }, function (error) {
                console.log('error!', error)
            }, 'giftCert');
        }

        // execute page specific initializations
        if (ns && pages[ns]) {
            switch (ns) {
                case 'search':
                require.ensure([], function (require) {
                    var chunk = require('./pages/search');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'search');
                aep.searchCall();
                break;
                case 'productRefresh':
                require.ensure([], function (require) {
                    var chunk = require('./pages/productRefresh');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'productRefresh');
                aep.productCall();
                break;
                case 'productRefresh24':
                    require.ensure([], function (require) {
                        var chunk = require('./pages/productRefresh24/entry');
                        if (chunk.init) {
                            chunk.init();
                        }
                    }, function (error) {
                        console.log('error!', error)
                    }, 'productRefresh24');
                break;
                case 'account':
                require.ensure([], function (require) {
                    var chunk = require('./pages/account');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'account');
                break;
                case 'myaccount':
                require.ensure([], function (require) {
                    var chunk = require('./pages/myaccount');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'myaccount');
                break;
                case 'rlhome':
                require.ensure([], function (require) {
                    var chunk = require('./pages/enhancedPDP');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'rlhome');
                break;
                case 'storefront':
                require.ensure([], function (require) {
                    var chunk = require('./pages/storefront');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'storefront');
                break;
                case 'compare':
                require.ensure([], function (require) {
                    var chunk = require('./pages/compare');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'compare');
                break;
                case 'wishlist':
                require.ensure([], function (require) {
                    var chunk = require('./pages/wishlist');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'wishlist');
                break;
                case 'registry':
                require.ensure([], function (require) {
                    var chunk = require('./pages/registry');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'registry');
                break;
                case 'storelocator':
                require.ensure([], function (require) {
                    var chunk = require('./pages/storelocator');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'storelocator');
                break;
                case 'customerservice':
                require.ensure([], function (require) {
                    var chunk = require('./pages/customer-service');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'customerservice');
                break;
                case 'qrcode':
                require.ensure([], function (require) {
                    var chunk = require('./pages/qrcode');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'qrcode');
                break;
                case 'qrcodeRD':
                require.ensure([], function (require) {
                    var chunk = require('./pages/qrcodeRD');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'qrcodeRD');
                break;
                case 'returns':
                require.ensure([], function (require) {
                    var chunk = require('@foundation/js/pages/returns');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'returns');
                break;
                case 'stylequiz':
                require.ensure([], function (require) {
                    var chunk = require('./pages/stylequiz');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'stylequiz');
                break;
                case 'myWardrobe':
                require.ensure([], function (require) {
                    var chunk = require('./pages/wardrobe');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'myWardrobe');
                break;
                case 'homeInquiry':
                require.ensure([], function (require) {
                    var chunk = require('./pages/homeInquiry');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'homeInquiry');
                break;
                case 'cart':
                require.ensure([], function (require) {
                    var chunk = require('./pages/cart');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'cart');
                break;
                case 'magazine':
                // old rl mag namespace (needed to support new nav on old pages)
                require.ensure([], function (require) {
                    var chunk = require('@rlmagazine/js/pages/magazine');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'rlmag');
                break;
                case 'rlmag':
                require.ensure([], function (require) {
                    var chunk = require('@rlmagazine/js/pages/rlmag');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'rlmag');
                break;
            }
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        //PREVAIL-Initializations
        // if (isSPCEnabled) {
        //     require('./spc').init();
        // }

        if (isBISNEnabled) {
            require('./bisn').init();
        }

        aep.coreCall();
        
        if(SitePreferences.QUICK_SHOP_ENABLED){
            require.ensure([], function (require) {             
               var chunk = require('./rl_quickshop');
               if (chunk.init) {
                   chunk.init();
               }
           }, function (error) {
               console.log('error!', error)
           }, 'quickshop');
        }
    } , 
    cookieConsent: {
        init: function() {
            var cookieConsentExpiration = SitePreferences.cookieConsentExpiration;
            var cookieConsentName = 'cookieConsentOW';
            
            if (!util.getCookie(cookieConsentName)) {
                $('.cookie-bar').show(500);
                util.setCookie(cookieConsentName,'true',cookieConsentExpiration);                
            }
            
            $('.js-cookie-anchor').on('click',function() {
                $('.cookie-bar').hide(500);
            });
        }       
    }
};


//DIGITAL-4480 Dialog not recentering on resize 
$(window).on('resize', _.debounce(
    function() {
        var position = $('.ui-dialog-content:not(.qs-dialog-content)').dialog('option', 'position');
        $('.ui-dialog-content:not(.qs-dialog-content)').dialog('option', 'position', position);
    },
    200)
);

// general extension functions
(function() {
    String.format = function() {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

$('[name$="_giftcert_purchase_messageline1"]').addClass('Profanity');
$('[name$="_giftcert_purchase_messageline2"]').addClass('Profanity');
$('[name$="_giftcert_purchase_messageline3"]').addClass('Profanity');
$('[name$="_giftcert_purchase_messageline4"]').addClass('Profanity');
$('[name$="_contactus_comment"]').addClass('Profanity');

if (SitePreferences.ANALYTICS_ENABLED != undefined && SitePreferences.ANALYTICS_ENABLED) {
    if ($('body').find('video').length > 0 && digitalData != null && digitalData.page != undefined) {
        if (digitalData.page.pageType == 'product') {
            digitalData.page.video = $('.video-button').length > 0 ? 'HTML5' : 'no video';
        } else {
            digitalData.page.video = $('body').find('video').length > 0 ? 'HTML5' : 'no video';
        }
    }
}

//Polo Quick Shop Start
function getUrlParameter(sPageURL, sParam) {
	var sURLVariables = sPageURL.split('&'),
     sParameterName,
     i;
	var carVal = 'rlc-spinslider';
	for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');
	
			if (sParameterName[0] === sParam && sParameterName[1] !== undefined) {
				carVal =  decodeURIComponent(sParameterName[1]);
			}
	}
	return carVal;
}

function getWindowUrlWithoutParams() {
	var windowUrl = window.location.href;
	if (windowUrl.indexOf("&")>-1){
		windowUrl = windowUrl.split('&')[0];		
	}
	return windowUrl;
}

$('.rlc-qslink').off('click').on('click',function(e){
	e.preventDefault();

	var poloId =  $(this).attr('data-pid');
	var poloColor = $(this).attr('data-color');
	var poloFitProdId = $(this).attr('data-fitPid');


    var params = {
        poloId: poloId,
        quickShopPopUp: true,
        userSelectedColor: poloColor
    };
    document.dispatchEvent(new CustomEvent('openQuickShop', { bubbles: false, detail: { checkProducts: false, type: 'poloshop' , productID: poloId, params:params} }));
});

$('.rlc-moredetails').off('click').on('click',function(e){
	e.preventDefault();
	var poloHref =  $(this).attr('href');
	var sliderParamVal = getUrlParameter(poloHref,'ccarousel');
	//This condition is to make sure not take the ccarousel parameter if its in the url.
	var getWindowUrl = getWindowUrlWithoutParams();
	var BackToColorUrl = util.appendParamToURL(getWindowUrl, 'ccarousel', sliderParamVal);
	var params = {
			BackToColorUrl: BackToColorUrl
   	 };
    $.ajax({
        type: 'GET',
        async: true,
        dataType: 'json',
        contentType: 'application/json',        
        url: util.appendParamsToUrl(Urls.setBackToColorUrl, params),
        success: function(response) {
        	window.location.href = poloHref;       
        }
    });
});
//Polo Quick Shop End

//Zero state Orders
if ($('.myRL_account #for-you-prod-recs-tab').length > 0) {
    //Cat Recs JS
    var swiper = new Swiper('.monetate-swiper-container', {
        slidesPerView: 4,
        spaceBetween: 16,
        allowTouchMove: false,
        scrollbar: {
            el: '.monetate-swiper-scrollbar',
            clickable: 'true'
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                spaceBetween: 16,
                allowTouchMove: true
            }
        }
    });
}

//Launch appointment modal from content page.
$('.store-content-requestappt').on('click', function(e) {
	e.preventDefault();
	var appointment = require('./appointment');
	dialog.open({
		url: util.appendParamsToUrl(Urls.appointmentURL),
		options: {
            dialogClass: 'pdp-appointment',
            title: Resources.BOOK_AN_APPOINTMENT_TILE,
            width: 775,
			open: function(e) {
                validator.init();
                $('body').addClass('prevent-scroll');
                if (document.getElementById('timetrade')) {
                    $('#dialog-container').addClass('timetrade');
                }
			},
	        beforeClose: function () {
	        	$('body').removeClass('prevent-scroll');
	        },
		}
	});	
	
});

if ($('.myrl-order-shipments').length > 0) {
    $('.myrl-order-shipments').each(function() {
        if ($(this).children().length == 0) {
            $(this).detach();
        }
    });
}

$('.myrl-order-shipments-tab, .in-progress-tab').click(function() {
    var shipmentId;

    if ($(this).hasClass('unselected')) {
        //save data-shipmentnum value shipmentId
        shipmentId = $(this).hasClass('in-progress-tab') ? 'in-progress' : $(this).data('shipmentnum');

        //Go up to .myrl-order-shipments
        //add class unselected to all .myrl-order-shipments-tab
        $(this).closest('.myrl-order-shipments').find('.myrl-order-shipments-tab').removeClass('selected').addClass('unselected');
        //add class unselected .in-progress-tab
        $(this).closest('.myrl-order-shipments').find('.in-progress-tab').removeClass('selected').addClass('unselected');
        //add class selected to clicked item
        $(this).removeClass('unselected').addClass('selected');

        //go up two parents .order-details
        //add Hide to all .row
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-thumbs').find('.row').removeClass('Show').addClass('Hide');
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-history-details-list-text').find('.est-delivery').find('.expected-date-row').removeClass('Show').addClass('Hide');
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-btns').find('.order-sterling-link').removeClass('Show').addClass('Hide');

        //set shipmentId to Show
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-thumbs').find('.row.' + shipmentId).removeClass('Hide').addClass('Show');
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-history-details-list-text').find('.est-delivery').find('.expected-date-row.' + shipmentId).removeClass('Hide').addClass('Show');
        $(this).closest('.order-details').find('li').find('.order-history-header').find('.order-btns').find('.order-sterling-link.' + shipmentId).removeClass('Hide').addClass('Show');
    }
});

// initialize app
$(document).ready(function() {
    app.init();
});
